require('./bootstrap');
require('./jquery.redirect');
require('./jquery.cookie.min');

import { library, dom } from "@fortawesome/fontawesome-svg-core";
import { faSync } from "@fortawesome/free-solid-svg-icons/faSync";
import { faAngleUp } from "@fortawesome/free-solid-svg-icons/faAngleUp";
import { faAngleDown } from "@fortawesome/free-solid-svg-icons/faAngleDown";
import { faTimesCircle } from "@fortawesome/free-solid-svg-icons/faTimesCircle";
import { faCheck } from "@fortawesome/free-solid-svg-icons/faCheck";
library.add(faSync);
library.add(faAngleUp);
library.add(faAngleDown);
library.add(faTimesCircle);
library.add(faCheck);
dom.watch();

require('./html/general');
require('./html/forms');
require('./html/captcha');
require('./html/svg');
require('./html/mapa');
require('./html/datepicker');
require('./html/duplicate');
require('./html/crearMensaje');

