$(document).ready(function(){
  if (window.IsDuplicate()) {
    $('.body-custom').addClass('d-none');
    $('#modal-duplicado').removeClass('d-none');
  }


  if (Cookies.get('subopcion_login') == 'delitoelectoral') {
	 Cookies.set('subopcion_login', 'Pasodelitoelectoral', { expires: 1 });
	 Cookies.set('delito', '333', { expires: 1 });
	 $.redirect(miurl+"/delitos", { '_token': $('meta[name="csrf-token"]').attr('content') },'POST');
  }

	if (window.location.href.search('info-denuncia')!=-1) {
		$('footer').addClass('fijafooter');
	} else 
	{ $('footer').removeClass('fijafooter'); }

  $(".colapsa").on('click', function() {
	  $($(this.parentElement)[0].getElementsByTagName('svg')).toggleClass("down");
   })

  $(".close-modal-duplicado").on('click', function() {
    var objWindow = window.open('https://adip.cdmx.gob.mx/', "_self");
        objWindow.close();
  });

  if ($("#home-denuncia").length)  {
	//$('#msgModale').modal('show');
	$('.fondo_transparente').css('display','block');  
        $.removeCookie("opcion_login");
  }

document.getElementsByClassName("modal_cerrar")[0].addEventListener("click",function(){
           $('.fondo_transparente').css('display','none'); 
});

  $("button[type='submit']").on('click',(function(e) {
	  $(".forms-denuncias input[type=text], .forms-denuncias input[type=email], .forms-denuncias textarea").each(function () {
	    $input=$(this);
	    var type = $input.attr('name');
            //console.log('name='+type);
	    if(type != 'contrasena' && type != 'fecha_nacimiento' && type != 'sexo' && type != 'genero'){
               $input.val($input.val().toUpperCase());
	    }
	  });
  }));
  try { $('#wl_captcha').focus()  } catch(e) { } ;
  /**
   * Validación de emails
   */
  window.validateEmail = function (email) {
      var regExp = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return regExp.test(email);
  };

  $('#is_ninguno').on('change' , function(){
    ninguno(this);
  });

  $('#tipodocumento').on('change' , function(){
    tipodocumento(this);
  });

  $('#parentesco').on('change' , function(){
    parentesco(this);
  });

  $('#idtipoautoridad').on('change' , function(){
    tipoautoridad(this);
  });

  $('#is_noaplica').on('change' , function(){
    noaplica(this);
  });

  $('#is_otroimg').on('change' , function(){
    otroimg(this);
  });

  $('#de_otroimg').on('change' , function(){
    otroimg(this);
  });

  $('#is_otromedio').on('change' , function(){
    otromedio(this);
  });

  $('input[type="radio"][name="is_siima"]').on('change' , function(){
    siima(this);
  }); 

  $('input[type="radio"][name="ayuda"]').on('change' , function(){
    ayuda(this);
  }); 

  $('input[type="radio"][name="determinacionjudicial"]').on('change' , function(){
    determinacion(this);
  }); 

  $('input[type="radio"][name="de_siima"]').on('change' , function(){
    siima(this);
  }); 

  $('input[type="radio"][name="de_siid"]').on('change' , function(){
    siid(this);
  }); 

  $('input[type="radio"][name="de_siinfo"]').on('change' , function(){
    siinfo(this);
  }); 

  window.ninguno = function(x) {
    if ($(x).is(":checked")) {
      $('.ninguno').prop('disabled',true);
      $('.ninguno').prop('checked',false);
    } else {
      $('.ninguno').prop('disabled',false);
    }
  }

  window.noaplica = function(x) {
    if ($(x).is(":checked")) {
      $('.noaplica').prop('disabled',true);
      $('.noaplica').prop('checked',false);
    } else {
      $('.noaplica').prop('disabled',false);
    }
  }


  window.otroimg = function(x) {
        if ($(x).length>0) {
	    if ($(x).is(":checked")) {
	      $('.otroimg').removeClass('d-none');
	    } else {
	      $('.otroimg').addClass('d-none');
	      $('.otroimg').val('');
	    }
	}
  }

  window.otromedio = function(x) {
    if ($(x).is(":checked")) {
      $('.otromedio').removeClass('d-none');
    } else {
      $('.otromedio').addClass('d-none');
      $('.otromedio').val('');
    }
  }

  window.parentesco = function(x) {
    if (x.value==10) {
      $('#otrarela').removeAttr('disabled');
    } else {
      $('#otrarela').attr('disabled','disabled');
    }
  }

  window.tipodocumento = function(x) {
    if (x.value==10) {
      $('#otrodocu').removeAttr('disabled');
    } else {
      $('#otrodocu').attr('disabled','disabled');
    }
  }


  window.tipoautoridad = function(x) {
    if (x.value==3) {
      $('#otraautoridad').removeAttr('disabled');
    } else {
      $('#otraautoridad').attr('disabled','disabled');
    }
  }

  window.siima = function(x) {
    if (x.value==1) {
      $('.siima').removeClass('d-none');
      otroimg($('#is_otroimg'));
      otroimg($('#de_otroimg'));
    } else {
      $('.siima').addClass('d-none');
      $('.siima').prop('checked',false);
    }
  }

  window.determinacion = function(x) {
    if (x.value==1) {
      $('#determinaciondocto').removeAttr('disabled');
      $('#determinaciondocto').attr('required','required');
    } else {
      $('#determinaciondocto').attr('disabled','disabled');
      $('#determinaciondocto').removeAttr('required');
    }
  }

  window.ayuda = function(x) {
    if (x.value=='si') {
      $('#ayuda_numero').removeAttr('readonly');
      $('#ayuda_numero').attr('required','required');
    } else {
      $('#ayuda_numero').attr('readonly','readony');
      $('#ayuda_numero').removeAttr('required');
      $('#ayuda_numero').val('');
    }
  }


  window.siid = function(x) {
    if (x.value==1) {
      $('.siid').removeClass('d-none');
      if ($('input[type="radio"][name="de_siinfo"]:checked').length>0) {
	     siinfo($('input[type="radio"][name="de_siinfo"]:checked')[0]);
      }
    } else {
      $('.siid').addClass('d-none');
      $('.siinfo').addClass('d-none');
      $('.siid').val('');
    }
  }

  window.siinfo = function(x) {
    if (x.value==1) {
      $('.siinfo').removeClass('d-none');
      $("#de_nombre").prop('required',true);
      $("#de_primer_apellido").prop('required',true);
      $("#de_calle").prop('required',true);
      $("#de_numero_exterior").prop('required',true);
    } else {
      $('.siinfo').addClass('d-none');
      $("#de_nombre").prop('required',false);
      $("#de_primer_apellido").prop('required',false);
      $("#de_calle").prop('required',false);
      $("#de_numero_exterior").prop('required',false);
      $('.siinfo').val('');
    }
  }




  ninguno($('#is_ninguno'));
  noaplica($('#is_noaplica'));
  otromedio($('#is_otromedio'));

  if ($('input[type="radio"][name="is_siima"]:checked').length>0) {
     siima($('input[type="radio"][name="is_siima"]:checked')[0]);
     otroimg($('#is_otroimg'));
  }
  if ($('input[type="radio"][name="de_siid"]:checked').length>0) {
     siid($('input[type="radio"][name="de_siid"]:checked')[0]);
  }
  if ($('input[type="radio"][name="de_siinfo"]:checked').length>0) {
     siinfo($('input[type="radio"][name="de_siinfo"]:checked')[0]);
  }
  if ($('input[type="radio"][name="de_siima"]:checked').length>0) {
     siima($('input[type="radio"][name="de_siima"]:checked')[0]);
     otroimg($('#de_otroimg'));
  }


  window.noreintentar = function(archivo) {

          var data = {
	    "archivo": archivo,
            "_token": document.head.querySelector('meta[name="csrf-token"]').content
          };

            $.ajax({
                   type: "POST",
                   url: $('#url').val()+'/noreintentar',
		   data:data,
                   success: function(datax) {
		          window.location.href = $('#url').val();
		   }
	      });

  }

	/* solo para cargar codigo al boton de enviar denuncia */
  window.realizadenuncia = function() {
	  $('.realizar-denuncia').on('click' , function(){
	      if (!ValidCaptcha()) {
		  $('.error-input-codigo-seg').removeClass('d-none');
		  return false;
	      }
	      $('.error-input-codigo-seg').addClass('d-none');
	      $(this).addClass('disabled')
            thistop=this;
            crearMensaje(false,'Atención','Enviando la denuncia',0);

          var data = {
            "_token": document.head.querySelector('meta[name="csrf-token"]').content
          };

	    $.ajax({
		   type: "POST",
		   url: $('#url').val()+$(this)[0].dataset.ref,
		   data: data,
		   success: function(datax) {
			            $('#msgModal').modal('hide');
	                            $(thistop).removeClass('disabled');
                                    $('#wl_captcha_bot').trigger('click');
                                    $('#wl_captcha').val('');
                                    $('#wl_captcha').focus();
			            if ('error' in datax) {
                                           if (datax.deserr.indexOf('timed out after')==-1 && datax.deserr.indexOf('No se encuentra el recurso')==-1) {
                                                crearMensaje(true,'Atención',datax.error);
                                                $('#wl_captcha_bot').trigger('click');
                                                $('#wl_captcha').val('');
                                                $('#wl_captcha').focus();
						return;
				           } else {
                                                crearMensaje(true,'Atención','<p class="text-danger font-weight-bold text-center">Se están presentando problemas técnicos '+
							          'con el servicio de la FGJ.</p><br>'+
							          '<p class="text-justify">En unos momentos más te enviaremos un correo electrónico informándote si fue exitoso '+
							          'o no tu registro. Te pedimos una disculpa por los inconvenientes.</p><b>',0);
						$('#bodyMsgModal').removeClass('alert-danger');
						$('.close').on('click', function(){
							noreintentar(datax.archivo);
					        });
						return;
				           }
                                    }
		                    
			            crearMensaje(false,'Atención !!',
					              '<h3 class="text-center">Tu solicitud ha sido enviada</h3>'+
					              '<p class="mb-4 text-center">Tu información será revisada, una vez aprobada se te notificará al correo registrado.</p>'+
					              '<p class="text-center"><strong>Tu número de folio es</strong></p>'+
					              '<p class="text-success text-center">'+datax.folio+'</p>'
					             ,0).then(function () {
							     window.location.href = $('#url').val();
						     });
		   },
		   error: function () {
                                    crearMensaje(true,'Atención','Error desconocido al enviar la denuncia');
	                            $(thistop).removeClass('disabled')
                                    $('#wl_captcha_bot').trigger('click');
                                    $('#wl_captcha').val('');
                                    $('#wl_captcha').focus();
		   }
	      });
	  });
  }
	realizadenuncia();

	$(function () {
	  $('[data-toggle="tooltip"]').tooltip()
	})
var archivogrande =     '<div class="row message-alert"><div class="col-lg-12"><div class="alert alert-danger alert-dismissable"><button type="button" class="close" data-dismiss="alert">&times;</button>   <span class="glyphicon glyphicon-remove"></span>archivo sobrepasa de 4mb </div> </div></div>';

var almenos =     '<div class="row message-alert"><div class="col-lg-12"><div class="alert alert-danger alert-dismissable"><button type="button" class="close" data-dismiss="alert">&times;</button>   <span class="glyphicon glyphicon-remove"></span>Al menos debe de anexar un documento para comprobar su relación con la victima.</div> </div></div>';

var almenosusado =     '<div class="row message-alert"><div class="col-lg-12"><div class="alert alert-danger alert-dismissable"><button type="button" class="close" data-dismiss="alert">&times;</button>   <span class="glyphicon glyphicon-remove"></span>Si es usado el vehiculo debe de adjuntar la carta responsiva o el contrato de compra venta.</div> </div></div>';

var almenosungrupo =     '<div class="row message-alert"><div class="col-lg-12"><div class="alert alert-danger alert-dismissable"><button type="button" class="close" data-dismiss="alert">&times;</button>   <span class="glyphicon glyphicon-remove"></span>Al menos debes de seleccionar un grupo.</div> </div></div>';

var almenosunmedio =     '<div class="row message-alert"><div class="col-lg-12"><div class="alert alert-danger alert-dismissable"><button type="button" class="close" data-dismiss="alert">&times;</button>   <span class="glyphicon glyphicon-remove"></span>Al menos debes de seleccionar un medio de difusión del contenido sexual.</div> </div></div>';

var almenosuncontenido =     '<div class="row message-alert"><div class="col-lg-12"><div class="alert alert-danger alert-dismissable"><button type="button" class="close" data-dismiss="alert">&times;</button>   <span class="glyphicon glyphicon-remove"></span>Al menos debes de seleccionar el tipo de contenido multimedia que fue difundido.</div> </div></div>';

var extensiones =     '<div class="row message-alert"><div class="col-lg-12"><div class="alert alert-danger alert-dismissable"><button type="button" class="close" data-dismiss="alert">&times;</button>   <span class="glyphicon glyphicon-remove"></span>Solo archivos con extension jpeg,jpg,png y/o pdf son permitidos</div> </div></div>';

var template_error = '<div class="tooltip bg-danger" role="tooltip"><div class="arrow "></div><div class="tooltip-inner"></div></div>'

$("#frm_sustraccion_menores").submit(function(e) {
  if ($("#sm_relacionvictima").val()==5 || $("#sm_relacionvictima").val()==4 || $("#sm_relacionvictima").val()==6
    || $("#sm_relacionvictima").val()==2 || $("#sm_relacionvictima").val()==1) {
      noarchivo=true;
      $(".almenosuno").each(function() {
        if ($(this).val()!="") {
          noarchivo=false;
        }
      })
      if (noarchivo) {
        $(".alertasm").append(almenos);
         setTimeout(function(){ $(".message-alert").remove(); }, 5000 );
         $("#is_adultomayor1").focus();
         e.preventDefault();
      }
  }
});

$("#frm_delitoelectoral").submit(function(e) {
    if($(".siima:checkbox:checked").length == 0 && $('input[type="radio"][name="de_siima"]:checked').val()==1) {
        $(".alertaima").append(almenosuncontenido);
        $(window).scrollTop($('.alertaima').offset().top);
         setTimeout(function(){ $(".message-alert").remove(); $("#de_siima1").focus();}, 5000 );
         e.preventDefault();
         return;
    }
    e.preventDefault(); // avoid to execute the actual submit of the form.
    var form = $(this);
    var url = form.attr('action');

    $.ajax({
           type: "POST",
           url: url,
           data: form.serialize(), // serializes the form's elements.
           success: function(datax)
                     {
                         $("#enviadenuncia").remove();
                         $(".alerta").append(datax[0]);
                          $(window).scrollTop($('.alerta').offset().top);
                          setTimeout(function(){ $(".message-alert").remove(); }, 5000 );
                          if (datax[1]!='') {
                            $("#tabs").append(datax[1]);
                          }
			  $('#wl_captcha_bot').trigger('click');
                          $("#de_iddelito").focus();
			  realizadenuncia();
			  muestracheck();
                     }
      });
});

$("#frm_intimidadsexual").submit(function(e) {

    if($(".grupo:checkbox:checked").length == 0) {
        $(".alertagrupo").append(almenosungrupo);
        $(window).scrollTop($('.alertagrupo').offset().top);
         setTimeout(function(){ $(".message-alert").remove(); }, 5000 );
         $("#is_adultomayor1").focus();
         e.preventDefault();
         return;
    }

    if($(".medios:checkbox:checked").length == 0) {
        $(".alertamedios").append(almenosunmedio);
        $(window).scrollTop($('.alertamedios').offset().top);
         setTimeout(function(){ $(".message-alert").remove(); $("#is_impreso1").focus();}, 5000 );
         e.preventDefault();
         return;
    }

    if($(".siima:checkbox:checked").length == 0 && $('input[type="radio"][name="is_siima"]:checked').val()==1) {
        $(".alertaima").append(almenosuncontenido);
        $(window).scrollTop($('.alertaima').offset().top);
         setTimeout(function(){ $(".message-alert").remove(); $("#is_siima1").focus();}, 5000 );
         e.preventDefault();
         return;
    }



    e.preventDefault(); // avoid to execute the actual submit of the form.

    var form = $(this);
    var url = form.attr('action');

    $.ajax({
           type: "POST",
           url: url,
           data: form.serialize(), // serializes the form's elements.
           success: function(data)
                     {
                         $("#enviadenuncia").remove();
                         $(".alerta").append(data[0]);
                          $(window).scrollTop($('.alerta').offset().top);
                          setTimeout(function(){ $(".message-alert").remove(); }, 5000 );
                          if (data[1]!='') {
                            $("#tabs").append(data[1]);
                          }
			  $('#wl_captcha_bot').trigger('click');
                          $("#rv_nombre_ag").focus();
			  realizadenuncia();
                     }
      });
});

$("#frm_robovehiculosin").submit(function(e) {

  noarchivo=true;
  $(".almenosuno").each(function() {
    if ($(this).val()!="") {
      noarchivo=false;
    }
  })

  if ($('input[type="radio"][name="rv_usado"]:checked').val()=='si') {
      if (noarchivo) {
        $(".alertasm").append(almenosusado);
         setTimeout(function(){ $(".message-alert").remove(); }, 5000 );
         $("#rv_responsivadocto").focus();
         e.preventDefault();
         return;
      }
  }

    e.preventDefault(); // avoid to execute the actual submit of the form.

    var form = $(this);
    var url = form.attr('action');

    $.ajax({
           type: "POST",
           url: url,
           data: form.serialize(), // serializes the form's elements.
           success: function(data)
                     {
                         $(".alerta").append(data[0]);
          	              setTimeout(function(){ $(".message-alert").remove(); }, 5000 );
                          if (data[1]!='') {
                            $("#enviadenuncia").remove();
                            $("#tabs").append(data[1]);
                          }
                          $("#rv_nombre_ag").focus();
                          muestracheck();
                          validaformas();
                     }
      });
});

  setTimeout(function(){
    $(".message-alert").remove();
  }, 5000 );

  $('.btn-perdiste').on('click', function(){
    if ($(this).hasClass('disabled')){
      $('.error-message').removeClass('d-none');
    }else{
      var current = $(this).attr('data-current');
      var next = $(this).attr('data-next');
      var select = $("."+current+" .radio-q:checked").val();
      var key = $("."+current+" .radio-q:checked").attr('data-key');
      var delito = $("."+current+" .radio-q:checked").attr('data-delito');
      var delito_no = $("."+current+" .radio-q:checked").attr('data-no');

      $('.error-message').addClass('d-none');
      if(select == 'terminar'){
        window.location.href = $('#url').val()+'/agencias-ministerio-publico';
        return false;
      }else if(select == 'formulario-denuncia'){
        document.cookie = "extravio=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
        Cookies.set('delito', 'otro', { expires: 1 });
        window.location.href = $('#url').val()+'/delitos';
        return false;
      }else if(key == 'tipo-delito'){
        var adulto = $("."+current+" .radio-q:checked").attr('data-adulto');
        getOpenDelito(select, current, next, key, delito_no, delito, adulto);
        return false;
      }else if(key == 'extravio'){
        var extravio_no = $("."+current+" .radio-q:checked").attr('data-id');
        var name = $("."+current+" .radio-q:checked").val();

        document.cookie = "delito=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
        Cookies.set('extravio', extravio_no, { expires: 1 });
        Cookies.set('tipo', name, { expires: 1 });

        window.location.href = $('#url').val()+'/extravio';
        return false;
      }

      getOpenNextPerdiste(select, current, next, key, delito, delito_no);

    }

    if($(this).hasClass('btn-denuncia')){
      $('.realizar-denuncia').removeClass('d-none');
    }
  });

  $('.step li').on('click', function(){
    var active = $(this).attr('data-active');
    $(this).nextAll().addClass('d-none');
    $('.info-delito').addClass('d-none');
    $('.step-content').addClass('d-none');
    $('.realizar-denuncia').addClass('d-none');
    if(active == 's-victima'){
      $('.step-content.s-victima').removeClass('d-none');
      return false;
    }

    $('.step-content.'+active+'.open').removeClass('d-none');
    fixedFooter();
  });

  $(".radio-q").change(function () {
    if ($(this).is(":checked")) {
      $('.btn-perdiste').removeClass('disabled');
    }
  });

  $(".tamisi").change(function () {
   if ($(".tamisi:checked").length>0) {
      $('#vf_antecedentevio1').prop( "checked", true );
   }
  });

  $(".tamino").change(function () {
   if ($(".tamisi:checked").length==0) {
      $('#vf_antecedentevio1').prop( "checked", false );
      $('#vf_antecedentevio0').prop( "checked", true );
   }
  });

  $(".tamisi").trigger('change');

  function getOpenNextPerdiste(select, current, next, key, delito, delito_no){
    $("." + next + " .radio-q").prop("checked", false);
    $("."+next+" .btn-perdiste").addClass('disabled');
    $('.step .'+next).removeClass('d-none').attr('data-active', current);
    $('.'+next+' .text').html(select);
    $('.step-content').addClass('d-none');

    if(next == 's-victima6'){
        $('.adulto-no').attr('data-delito', delito);
        $('.adulto-no').attr('data-no', delito_no);
    }
    $('.step-content.'+next).removeClass('open').addClass('d-none');
    $('.step-content.'+next+'.'+key).removeClass('d-none').addClass('open');
    fixedFooter();
  }

  function getOpenDelito(select, current, next, key, delito, delito_no, adulto){
    $('.step .'+next).removeClass('d-none').attr('data-active', current);
    $("."+next+" .btn-perdiste").addClass('disabled');
    $('.'+next).removeClass('d-none');
    $('.'+next+' .text').html(select);
    $('.step-content').addClass('d-none');

    $('.info-delito').addClass('d-none');
    $('.step-content.'+key+' .'+delito).removeClass('d-none');
    $('.step-content.'+key).removeClass('d-none');
    if(adulto == 'si'){
      $('.step-content .adulto').addClass('d-none');
      $('.step-content.'+key+' .'+delito+' .adulto').removeClass('d-none');
      Cookies.set('delito_adulto', 'si', { expires: 1 });
    }

    Cookies.set('delito', delito_no, { expires: 1 });

    $('.realizar-denuncia').removeClass('d-none');
    fixedFooter();
  }

  openTabHash();

  function openTabHash(){
    var hash = location.hash;
    hash = hash.replace( /^#/, "" );

    if(hash != ''){
      $('.tabs-form').addClass('d-none');
      $('.tabs-side li').removeClass('active');
      $('#'+hash).removeClass('d-none');
      $('.tabs-side li.'+hash).addClass('active');
    }
  }

  $('.tabs-side li').on('click', function(){
    var hash = $(this).attr('data-tab');

    $('.tabs-form').addClass('d-none');
    $('.tabs-side li').removeClass('active');
    $('#'+hash).removeClass('d-none');
    $('#'+hash+' :input:visible:enabled:first').focus();
    $('.tabs-side li.'+hash).addClass('active');
    fixedFooter();
  });


  $(".forms-denuncias input[type=text], .forms-denuncias input[type=email], .forms-denuncias textarea").each(function () {
    $input=$(this);
    var type = $input.attr('name');
    if(type != 'contrasena' && type != 'fecha_nacimiento' && type != 'sexo' && type != 'genero'){
        $(this).addClass('upper');
    }
  });


  $( "#estado option:selected" ).each(function() {
    var estado = $(this).attr('value');
    var url = $('#url').val();
    if(estado > 0){
      $('#municipio').empty();
      $('#colonia').empty();
      ajaxMunicipios(estado, url);
    }

  });

  $( "#vf_agresorrelacion_ag" ).change(function() {
         ajaxAgresorrelacion($(this).val());
  });


  function ajaxAgresorrelacion(idrelacion){
    var url = $('#url').val();
    $.ajax({
      type: 'GET',
      url: url+"/agresorrelacion/"+idrelacion,
      success: function(data) {
       if(data.length!=0) {
          if(data[0].anexadocto) {
                 $('#adjunto').removeClass('d-none');
                 $('#adjuntar').html(data[0].desdocto);
                 $('#vf_relaciondocto').data('idtipoarchivo',data[0].iddocto);
          } else {
                 $('#adjunto').addClass('d-none');
                 $('#adjuntar').html('');
                 $('#vf_relaciondocto').prop('required',false);
                 $('#vf_idtipoadjunto').val(0);
          }
          if(data[0].obligatorio) {
                 $('#vf_relaciondocto').prop('required',true);
          } else {
                 $('#vf_relaciondocto').prop('required',false);
          }
       } else {
                 $('#adjunto').addClass('d-none');
                 $('#adjuntar').html('');
                 $('#vf_relaciondocto').prop('required',false);
       }

      },
      error: function() {
          console.log('error');
      }
    });
  }
  $( "#vf_agresorrelacion_ag" ).trigger('change');

  $( "#estado" ).change(function() {
    var estado = $(this).val();
    var url = $('#url').val();
    $('#municipio').empty();
    $('#colonia').empty();
    $('#municipio-val').val('');
    $('#colonia-val').val('');
    ajaxMunicipios(estado, url);
  });

  function ajaxMunicipios(estado, url){
    $.ajax({
      type: 'GET',
      url: url+"/municipios/"+estado,
      success: function(data) {
        $('#municipio').append(data.html);
        var municipio = $('#municipio-val').val();
        if(municipio > 0){
          $('#colonia').empty();
          $('#municipio option[value="'+municipio+'"]').prop("selected", true);
          ajaxColonias(municipio, estado, url);
        }
      },
      error: function() {
          console.log('error');
      }
    });
  }

  $( "#municipio" ).change(function() {
    var municipio = $(this).val();
    var url = $('#url').val();
    var estado = $('#estado').val();
    $('#colonia').empty();
    $('#colonia-val').val('');
    ajaxColonias(municipio, estado, url);
  });

  function ajaxColonias(municipio, estado, url){
    $.ajax({
      type: 'GET',
      url: url+"/asentamientos/"+municipio+"/"+estado,
      success: function(data) {
        $('#colonia').append(data.html);
        var colonia = $('#colonia-val').val();
        $('#colonia option[value="'+colonia+'"]').prop("selected", true);
        var cp = $('#colonia option[value="'+colonia+'"]').attr('data-cp');
        if(cp > 0){
          if (cp.length == 4){
            cp = '0'+cp;
          }
          $('#cp').val(cp);
        }
	muestracheck();
      },
      error: function() {
          console.log('error');
      }
    });
  }

  $( "#lugar_municipio" ).change(function() {
    var municipio = $(this).val();
    var url = $('#url').val();
    var estado = 9;
    $('#lugar_colonia').empty();
    $('#lugar_colonia-val').val('');
    ajaxColoniasLugar(municipio, estado, url);
  });

  $( "#lugar_municipio option:selected" ).each(function() {
    var municipio = $( this ).attr('value');
    var url = $('#url').val();
    var estado = 9;
    $('#lugar_colonia').empty();
    ajaxColoniasLugar(municipio, estado, url);

  });


  function ajaxColoniasLugar(municipio, estado, url){
     if (municipio!=="") {
	    $.ajax({
	      type: 'GET',
	      url: url+"/asentamientos/"+municipio+"/"+estado,
	      success: function(data) {
		$('#lugar_colonia').append(data.html);
		var colonia = $('#lugar_colonia-val').val();
		$('#lugar_colonia option[value="'+colonia+'"]').attr("selected", "selected");
		var cp = $('#lugar_colonia option[value="'+colonia+'"]').attr('data-cp');

		if(cp > 0){
		  if (cp.length == 4){
		    cp = '0'+cp;
		  }
		  $('#lugar_cp').val(cp);
		}
		console.log('apenas lleno lugar_colina');
		muestracheck();

	      },
	      error: function() {
		  console.log('error');
	      }
	    });
     }
  }

  $( "#lugar_colonia" ).change(function() {
    $( "#lugar_colonia option:selected" ).each(function() {
      var cp = $( this ).attr('data-cp');
      if(cp > 0){
        if (cp.length == 4){
            cp = '0'+cp;
        }
        $('#lugar_cp').val(cp);
      }
    });
  });

  $( "#colonia" ).change(function() {
    $( "#colonia option:selected" ).each(function() {
      var cp = $( this ).attr('data-cp');
      if(cp > 0){
         if (cp.length == 4){
            cp = '0'+cp;
         }
        $('#cp').val(cp);
      }
    });
  });

  function refrescadom() {
        $('.btn-eliminar.testigo').on('click', function(event){
          td=this;
          var data = {
            "id": $(this)[0].dataset.id,
            "_token": document.head.querySelector('meta[name="csrf-token"]').content
          };
          $.ajax({
            url: miurl+"/testigos/borrar",
            dataType: 'json',
            type: 'POST',
            data: data,
            success: function(data) {
                     $(td).parent().parent().remove();
            },
            error: function(jqXHR, textStatus, errorThrown) {
              alert("No pudimos eliminar el testigo\n" + "Código de error: " + jqXHR.status + " " + textStatus);
            }
          });
        });

        $('.btn-eliminar.agraviado').on('click', function(event){
          td=this;
          var data = {
            "id": $(this)[0].dataset.id,
            "_token": document.head.querySelector('meta[name="csrf-token"]').content
          };
          $.ajax({
            url: miurl+"/agraviados/borrar",
            dataType: 'json',
            type: 'POST',
            data: data,
            success: function(data) {
                     $(td).parent().parent().remove();
                     //$('#nombre').focus();
                     $('#form-agraviados :input:visible:enabled:first').focus();
            },
            error: function(jqXHR, textStatus, errorThrown) {
              alert("No pudimos eliminar el agraviado\n" + "Código de error: " + jqXHR.status + " " + textStatus);
            }
          });
        });
  }


  $('#form-testigos').on('submit', function(event){
    event.preventDefault();
    var url = $('#url').val();
    var nombre = $('#form-testigos #nombre').val();
    var primer_apellido = $('#form-testigos #primer_apellido').val();
    var segundo_apellido = $('#form-testigos #segundo_apellido').val();
    var domicilio = $('#form-testigos #domicilio').val();
    var telefono = $('#form-testigos #telefono').val();
    var sexo = $('#form-testigos input:radio[name=sexo]:checked').val();
    var token = document.head.querySelector('meta[name="csrf-token"]');

    var data = {
      "nombre": nombre,
      "_token": token.content,
      "primer_apellido": primer_apellido,
      "segundo_apellido": segundo_apellido,
      "domicilio": domicilio,
      "telefono": telefono,
      "sexo" : sexo
    };
    $.ajax({
      url: url+"/testigos/guardar",
      dataType: 'json',
      type: 'POST',
      data: data,
      success: function(data) {
        $('.tabla-testigos').append(data);
        $('#form-testigos #nombre').val('');
        $('#form-testigos #primer_apellido').val('');
        $('#form-testigos #segundo_apellido').val('');
        $('#form-testigos #domicilio').val('');
        $('#form-testigos #telefono').val('');
        refrescadom();
      },
      error: function(jqXHR, textStatus, errorThrown) {
        alert("No pudimos guardar el testigo\n" + "Código de error: " + jqXHR.status + " " + textStatus);
      }
    });
  });

  refrescadom()

  $('#form-agraviados').on('submit', function(event){
    event.preventDefault();

    var hoy = $('#hechos_mes').attr('data-date');
    hoyD = new Date(hoy.substr(0, 4), hoy.substr(5, 2), hoy.substr(8, 2));
    if ($('#nacimiento_ano').val()!="" && $('#nacimiento_mes').val()!="" && $('#nacimiento_dia').val()!="") {
	    var nacimientofecha = new Date($('#nacimiento_ano').val(), $('#nacimiento_mes').val(), $('#nacimiento_dia').val());
	    if (nacimientofecha>hoyD) {
	      $('.error-input-fecha_nacimiento').text('La fecha de nacimiento no puede ser mayor a la del día de hoy').removeClass('d-none');
	      return false;
	    }
    }

    var data = $(this).serialize();
    $.ajax({
      url: miurl+"/agraviados/guardar",
      dataType: 'json',
      type: 'POST',
      data: data,
      success: function(data) {
        $('.tabla-agraviados').append(data);
        $('#form-agraviados').trigger("reset");
        $('#form-agraviados :input:visible:enabled:first').focus();
        refrescadom();
      },
      error: function(jqXHR, textStatus, errorThrown) {
        alert("No pudimos guardar el agraviado\n" + "Código de error: " + jqXHR.status + " " + textStatus);
      }
    });
  });


  $("#certificado").change(function() {
    var filename = this.files[0].name;
    $('.certificado-label').text(filename);
  });

  $("#clave").change(function() {
    var filename = this.files[0].name;
    $('.clave-label').text(filename);
  });

  $("#hechos_declaracion").change(function() {
    var filename = this.files[0].name;
    $('.hechos_declaracion-label').text(filename);
  });

  $("#vf_relaciondocto").change(function() {
    var filename = this.files[0].name;
    $('.vf_relaciondocto-label').text(filename);
  });

  $("input[type='file']").change(function() {
    var filename = this.files[0].name;
    $('.'+this.name+'-label').text(filename);
    enviaarchivo(this);
  });

  function creabotondescarga(que) {
                      var label= $("."+$(que)[0].id+"-label");
                      if (label.html().trim()!='Selecciona un archivo') {
                              $(que).removeAttr('required');
                              var quediv=$(que)[0].closest('div');
                              div = document.createElement('div');
                              div.setAttribute('class','ver-file ml-2');
                              div.setAttribute('onclick',"muestra_archivo('"+$(que)[0].id+"');");
                              div.setAttribute('id',"ver_"+$(que)[0].id);
                              quediv.parentNode.appendChild(div);
                      }
  }

  function enviaarchivo(que) {
                  $('#'+"ver_"+$(que)[0].id).remove();
                  var fileExtension = ['jpeg', 'jpg', 'png', 'pdf'];
                  var msg='Solo se permiten archivos tipo jpeg, jpg, png o pdf';
                  if (que.id=="certificado") {
                      var fileExtension = ['cer','txt'];
                      msg='Solo se permiten archivos tipo cer';
                  }
                  if (que.id=="clave") {
                      var fileExtension = ['key','txt'];
                      msg='Solo se permiten archivos tipo key';
                  }

                  if ($.inArray($(que).val().split('.').pop().toLowerCase(), fileExtension) == -1) {
                      $(que).attr('data-toggle','tooltip');
                      $(que).attr('title',msg);
                      $(que).attr('data-trigger','manual');
                      $(que).attr('data-template',template_error);
                      $(que).tooltip('show')
                      $('.'+que.name+'-label').text('Selecciona otro archivo');
                      setTimeout(function(){ $(que).tooltip('hide'); $(que).attr('data-toggle','')}, 5000 );
                      return false;
                  }
                  if (que.files[0].size>4000000) {
                    $(".alerta").append(archivogrande);
     	              setTimeout(function(){ $(".message-alert").remove(); }, 5000 );
                    $('.'+que.name+'-label').text('Selecciona otro archivo mas pequeño');
                    $(que).val(null);
                    return false;
                  }
                  var fd = new FormData();
                  fd.append($(que)[0].id,$(que)[0].files[0]);
                  fd.append('id',$(que)[0].id);
                  fd.append('idtipoarchivo','idtipoarchivo' in $(que).data() ? $(que).data().idtipoarchivo : 0)
                    $.ajax({
                       type: 'post',
                       url: miurl+'/guardaarchivo',
                       headers: {'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')},
                       contentType: false,
                       processData: false,
                       cache:       false,
                       data : fd,
                       success: function(data){
                              creabotondescarga(que)
                       },
                       error: function( jqXhr, textStatus, errorThrown ){
                          var errores=jqXhr.responseJSON.errors;
                          for (var x in errores) {
//                                     console.log("Error: ", errores[x]);
			      $(que).attr('data-toggle','tooltip');
			      $(que).attr('title',errores[x]);
			      $(que).attr('data-trigger','manual');
			      $(que).attr('data-template',template_error);
			      $(que).tooltip('show')
			      $('.'+que.name+'-label').text('Selecciona otro archivo');
			      setTimeout(function(){ $(que).tooltip('hide'); }, 5000 );
			      return false;

//                                     break;
                          }
                       }
                    });
  }
  $("input[type='file']").each(function() {creabotondescarga(this) });

window.muestra_archivo = function (id) {
                        $.redirect(miurl+"/verarchivo", { '_token': $('meta[name="csrf-token"]').attr('content') ,id : $("#"+id)[0].id },'POST','_blank');
        }

        $('input[name="vf_inseguro"]').on('click', function(e) {
             if (e.target.value=='si') {
                 $('#visita').removeClass('d-none');;
                 $('#vf_visita_co').prop('required',true);
             }
             if (e.target.value=='no') {
                 $('#visita').addClass('d-none');;
                 $('#vf_visita_co').prop('required',false);
                 $('#vf_visita_co').val('');
             }
        });

        $('input[name="sm_sabedonde"]').on('click', function(e) {
             if (e.target.value=='si') {
                 $('#sm_donde').prop('disabled',false);
                 $('#sm_donde').prop('required',true);
             }
             if (e.target.value=='no') {
                 $('#sm_donde').prop('disabled',true);
                 $('#sm_donde').prop('required',false);
                 $('#sm_donde').val('');
             }
        });


        $('input[name="rv_asegurado"]').on('click', function(e) {
             if (e.target.value=='si') {
                 $('#aseguradora').removeClass('d-none');;
                 $('#rv_aseguradora').prop('required',true);
             }
             if (e.target.value=='no') {
                 $('#aseguradora').addClass('d-none');;
                 $('#rv_aseguradora').prop('required',false);
                 $('#rv_aseguradora').val('');
             }
        });

        $('input[name="rv_camara"]').on('click', function(e) {
             if (e.target.value=='si') {
                 $('#descamara').removeClass('d-none');;
                 $('#rv_descamara').prop('required',true);
             }
             if (e.target.value=='no') {
                 $('#descamara').addClass('d-none');;
                 $('#rv_descamara').prop('required',false);
                 $('#rv_descamara').val('');
             }
        });
        $('input[name="rv_d911"]').on('click', function(e) {
             if (e.target.value=='si') {
                 $('#d911').removeClass('d-none');;
                 $('#rv_des911').prop('required',true);
             }
             if (e.target.value=='no') {
                 $('#d911').addClass('d-none');;
                 $('#rv_des911').prop('required',false);
                 $('#rv_des911').val('');
             }
        });
        $('input[name="rv_extran"]').on('click', function(e) {
             if (e.target.value=='si') {
                 $('#extran').removeClass('d-none');;
                 $('#rv_titulodocto').prop('required',true);
                 $('#rv_pedimentodocto').prop('required',true);
             }
             if (e.target.value=='no') {
                 $('#extran').addClass('d-none');;
                 $('#rv_titulodocto').prop('required',false);
                 $('#rv_titulodocto').val('');
                 $('#rv_pedimentodocto').prop('required',false);
                 $('#rv_pedimentodocto').val('');
             }
        });

        $('input[name="rv_usado"]').on('click', function(e) {
             if (e.target.value=='si') {
                 $('#usado').removeClass('d-none');;
             }
             if (e.target.value=='no') {
                 $('#usado').addClass('d-none');;
                 $('#rv_responsivadocto').val('');
                 $('#rv_compraventadocto').val('');
             }
        });



  $('.validate-long').on('keyup', function() {
    var val = $(this).val();
    var cantidad = $(this).attr('data-caracteres');

    if (val.length > cantidad) {
      return false;
    }
  });

  $('#rfc').on('keyup', function() {
    if ($(this).val()!='') {
	    var rfc = $(this).val();
	    var rfcCorrecto = rfcValido(rfc);

	    if (rfcCorrecto) {
	      $('.error-input-rfc').addClass('d-none');
	      $('.btn-generales').prop('disabled',false);
	    }else{
	      $('.btn-generales').prop('disabled',true);
	      $('.error-input-rfc').text('RFC No válido').removeClass('d-none');
	    }
    } else {
              $('.error-input-rfc').addClass('d-none');
              $('.btn-generales').prop('disabled',false);
    }
  });

  $('#rv_modelo_vehiculo').on('keyup', function() {
    var modelo = $(this).val();
    var modeloactual = $('#rv_modelo_vehiculo')[0].dataset.modelo;

    if (modelo>modeloactual+1) {
      $('.btn-robovehiculosin').prop('disabled',true);
      $('.error-input-modelo_vehiculo').text('Modelo es mayor al año actual').removeClass('d-none');
    }else{
      if (modelo>1920) {
        $('.btn-robovehiculosin').prop('disabled',false);
        $('.error-input-modelo_vehiculo').text('').addClass('d-none');
      } else {
        $('.btn-robovehiculosin').prop('disabled',true);
        $('.error-input-modelo_vehiculo').text('Modelo esta erroneo').removeClass('d-none');
      }
    }
  });

  $('#curp').on('keyup', function() {
    var curp = $(this).val();
    var curpCorrecto = curpValida(curp);

    if(curp == ''){
      $('.error-input-curp').addClass('d-none');
      $('.btn-generales').removeClass('disabled');
    }

    if (curpCorrecto) {
      $('.error-input-curp').addClass('d-none');
      $('.btn-generales').removeClass('disabled');
    }else{
      $('.btn-generales').addClass('disabled');
      $('.error-input-curp').text('CURP No válido').removeClass('d-none');
    }
  });

  $('#hechos_hora').on('keyup', function() {
    var hechos_hora = $(this).val();
    var horaCorrecto = horaValida(hechos_hora);

    if (horaCorrecto) {
      $('.error-input-hechos_hora').addClass('d-none');
      $('.btn-narracion').prop('disabled',false);
    }else{
      $('.btn-narracion').prop('disabled',true);
      $('.error-input-hechos_hora').text('Hora No válido').removeClass('d-none');
    }
  });

  $('.btn-narracion').on('click', function() {
    //event.preventDefault();
    var hoy = $('#hechos_mes').attr('data-date');
    hoyD = new Date(hoy.substr(0, 4), hoy.substr(5, 2), hoy.substr(8, 2));

    var hechosfecha = new Date($('#hechos_ano').val(), $('#hechos_mes').val(), $('#hechos_dia').val());
    if (hechosfecha>hoyD) {
      $('.error-input-fecha_hechos').text('La fecha de los hechos no puede ser mayor a la del dia de hoy').removeClass('d-none');
      return false;
    }
    if (validaDia($('#hechos_ano').val(),$('#hechos_mes').val(),$('#hechos_dia').val())==false) {
      $('.error-input-hechos_dia').text('El dia seleccionado esta erroneo').removeClass('d-none');
      event.preventDefault();
      return false;
    } else { $('.error-input-hechos_dia').text('').addClass('d-none'); }
    hoy = hoy.substr(0, 4)+hoy.substr(5, 2)+hoy.substr(8, 2);
    var hechosfecha = $('#hechos_ano').val()+$.strPad($('#hechos_mes').val(),2,'0')+$('#hechos_dia').val();
    if (hechosfecha==hoy) {
        event.preventDefault();
        $.ajax({
          type: 'GET',
          url: miurl+"/damehora",
          success: function(data) {
              hh=$('#hechos_hora').val().split(':')
              minhechos=hh[0]*60+hh[1];
              hhhoy=data.split(':');
              minhoy=hhhoy[0]*60+hhhoy[1];
              if (minhechos>minhoy) {
                  $('.error-input-hechos_hora').text('La hora es mayor a la actual').removeClass('d-none');
                  return;
              }
              $('.error-input-hechos_hora').text('').addClass('d-none');
              if ($('#frm_narracion')[0].checkValidity()==true) {
                 $('#frm_narracion').submit();
	      } else { $('#frm_narracion').addClass('was-validated'); }
          },
          error: function() {
              console.log('error');
          }
        });
    } else {
      $('.error-input-fecha_hechos').text('').addClass('d-none');
      return true;
    }
  });

  $('.btn-alimentos').on('click', function() {
		    event.preventDefault();
		  var forms = document.querySelector('#frm_alimentos');
		  if (forms.checkValidity() === false) {
		    forms.classList.add('was-validated');
		    return;
		  }
		    var hoy = $('#incumplimiento_mes').attr('data-date');
		    hoyD = new Date(hoy.substr(0, 4), hoy.substr(5, 2), hoy.substr(8, 2));

		    var hechosfecha = new Date($('#incumplimiento_ano').val(), $('#incumplimiento_mes').val(), $('#incumplimiento_dia').val());
		    if (hechosfecha>hoyD) {
		      $('.error-input-fecha_incumplimiento').text('La fecha de incumplimiento no puede ser mayor a la del dia de hoy').removeClass('d-none');
		      return false;
		    }
		    $(this).attr('disabled','disabled');
                    var thistop=this;

		    var data = $(forms).serialize();
		    $.ajax({
		      url: miurl+"/delitos/save/alimentos",
		      dataType: 'json',
		      type: 'POST',
		      data: data,
		      success: function(data) {
                        crearMensaje(false,'Atención','los datos fueron guardados correctamente');
		        $(thistop).removeAttr('disabled');
			muestracheck()
                        validaformas();
		      },
		      error: function(jqXHR, textStatus, errorThrown) {
			alert("No pudimos guardar los datos\n" + "Código de error: " + jqXHR.status + " " + textStatus);
		      }
		    });
  });

  $('.btn-falsificacion').on('click', function() {
                    event.preventDefault();
                  var forms = document.querySelector('#frm_falsificacion');
                  if (forms.checkValidity() === false) {
                    forms.classList.add('was-validated');
                    return;
                  }
                    $(this).attr('disabled','disabled');
                    var thistop=this;

                    var data = $(forms).serialize();
                    $.ajax({
                      url: miurl+"/delitos/save/falsificacion",
                      dataType: 'json',
                      type: 'POST',
                      data: data,
                      success: function(data) {
                        crearMensaje(false,'Atención','los datos fueron guardados correctamente');
                        $(thistop).removeAttr('disabled');
                        muestracheck()
                        validaformas();
                      },
                      error: function(jqXHR, textStatus, errorThrown) {
                        $(thistop).removeAttr('disabled');
                        alert("No pudimos guardar los datos\n" + "Código de error: " + jqXHR.status + " " + textStatus);
                      }
                    });
  });

  $('.btn-CobranzaIlegitima').on('click', function() {
                    event.preventDefault();
                  var forms = document.querySelector('#frm_cobranzailegitima');
                  if (forms.checkValidity() === false) {
                    forms.classList.add('was-validated');
                    return;
                  }
                    $(this).attr('disabled','disabled');
                    var thistop=this;

                    var data = $(forms).serialize();
                    $.ajax({
                      url: miurl+"/delitos/save/cobranzailegitima",
                      dataType: 'json',
                      type: 'POST',
                      data: data,
                      success: function(data) {
                        crearMensaje(false,'Atención','los datos fueron guardados correctamente');
                        $(thistop).removeAttr('disabled');
                        muestracheck()
                        validaformas();
                      },
                      error: function(jqXHR, textStatus, errorThrown) {
                        $(thistop).removeAttr('disabled');
                        alert("No pudimos guardar los datos\n" + "Código de error: " + jqXHR.status + " " + textStatus);
                      }
                    });
  });



  $('.btn-fraudeprocesal').on('click', function() {
                    event.preventDefault();
                  var forms = document.querySelector('#frm_fraude-procesal');
                  if (forms.checkValidity() === false) {
                    forms.classList.add('was-validated');
                    return;
                  }
                    $(this).attr('disabled','disabled');
                    var thistop=this;

                    var data = $(forms).serialize();
                    $.ajax({
                      url: miurl+"/delitos/save/fraudeprocesal",
                      dataType: 'json',
                      type: 'POST',
                      data: data,
                      success: function(data) {
                        crearMensaje(false,'Atención','los datos fueron guardados correctamente');
                        $(thistop).removeAttr('disabled');
                        muestracheck()
                        validaformas();
                      },
                      error: function(jqXHR, textStatus, errorThrown) {
                        $(thistop).removeAttr('disabled');
                        alert("No pudimos guardar los datos\n" + "Código de error: " + jqXHR.status + " " + textStatus);
                      }
                    });
  });

  $('.btn-RoboViolenciaMoral').on('click', function() {
                    event.preventDefault();
                  var forms = document.querySelector('#frm_roboviolenciamoral');
                  if (forms.checkValidity() === false) {
                    forms.classList.add('was-validated');
                    return;
                  }
                    $(this).attr('disabled','disabled');
                    var thistop=this;

                    var data = $(forms).serialize();
                    $.ajax({
                      url: miurl+"/delitos/save/roboviolenciamoral",
                      dataType: 'json',
                      type: 'POST',
                      data: data,
                      success: function(data) {
                        crearMensaje(false,'Atención','los datos fueron guardados correctamente');
                        $(thistop).removeAttr('disabled');
                        muestracheck()
                        validaformas();
                      },
                      error: function(jqXHR, textStatus, errorThrown) {
                        $(thistop).removeAttr('disabled');
                        alert("No pudimos guardar los datos\n" + "Código de error: " + jqXHR.status + " " + textStatus);
                      }
                    });
  });


  $('.btn-insolvencia').on('click', function() {
                  event.preventDefault();
                  var forms = document.querySelector('#frm_insolvencia');
                  if (forms.checkValidity() === false) {
                    forms.classList.add('was-validated');
                    return;
                  }
                    $(this).attr('disabled','disabled');
                    var thistop=this;

                    var data = $(forms).serialize();
                    $.ajax({
                      url: miurl+"/delitos/save/insolvencia",
                      dataType: 'json',
                      type: 'POST',
                      data: data,
                      success: function(data) {
                        crearMensaje(false,'Atención','los datos fueron guardados correctamente');
                        $(thistop).removeAttr('disabled');
                        validaformas();
			muestracheck();
                      },
                      error: function(jqXHR, textStatus, errorThrown) {
                        $(thistop).removeAttr('disabled');
                        alert("No pudimos guardar los datos\n" + "Código de error: " + jqXHR.status + " " + textStatus);
                      }
                    });
  });
 
  function  validaformas() {
        var hayerror=false;
        $('form:not([class])').each(function() {
          console.log('id='+this.id);
          if (this.checkValidity()==false) {
               hayerror=true;
               //console.log('no valido');
          } else {
               //console.log('si valido');
          }
        })
        if (hayerror==false) {
               muestrabotonDenuncia();
        }
  }

  function  muestracheck() {
        $('[data-tab]').each(function() {
	  var hayerror=false;
	  $('#'+this.dataset.tab).find('form').each(function() {
                  //console.log('formid='+this.id);
                  if (this.checkValidity()==true) {
                          console.log('correcto');
			  hayerror=true;
		  }  /*
		  else {
		  $(this).addClass('was-validated');
	   	  } */
	  })
	  if (hayerror==true) {
		  $(this).find('span').removeClass('d-none');
		  //console.log(' check='+$(this).find('.fa-check').length);
	  } 
        })
  }


  function muestrabotonDenuncia() {
           //console.log('haz denuncia');
	   $('#enviadenuncia').remove();
           var str =
                '<div id="enviadenuncia">'+
                        '<div class="form-group ui-widget">'+
                              '<label for="email" class="form-label-custom mb-1">Código de seguridad</label>'+
                              '<div class="input-group">'+
                                            '<input tabindex="-1" size=20 class="captcha form-control" readonly="on" type=text id="wl_captcha_img" name=wl_captcha_img title="Imagen de la captcha" ></input>&nbsp'+
                                            '<span class="input-group-addon d-flex align-items-center" id="wl_captcha_bot" name="wl_captcha_bot" onclick="ReDrawCaptcha(this);return false" data-toggle="tooltip" data-placement="top" title="De un clic aqui para cambiar el código de seguridad"><i class="fas fa-sync"></i></span>'+
                              '</div>'+
                        '</div>'+
                        '<div class="form-group ui-widget">'+
                            '<label for="email" class="form-label-custom mb-1">Teclee el código de seguridad SIN espacios*</label>'+
                            '<input class="form-control form-control-sm" tabindex="-1" size=20  type=text id="wl_captcha" name="wl_captcha" onfocus="DrawCaptcha(this)" placeholder="Teclee el código de seguridad" ></input>'+
                            '<p class="error-input error-input-codigo-seg d-none">El código de seguridad tecleado no checa</p>'+
                        '</div>'+
                        '<a href="#" data-ref="/delitos/denunciar" class="btn btn-primary realizar-denuncia">Enviar denuncia</a>'+
                '</div>';
           $("#tabs").append(str);
           realizadenuncia();
           $('#wl_captcha').focus();
  }

  $('#modelo_vehiculo').on('keyup', function() {
    var val = $(this).val();

    if (val.length == 4) {
      $('.error-input-modelo_vehiculo').addClass('d-none');
    }else{
      $('.error-input-modelo_vehiculo').text('Modelo No válido').removeClass('d-none');
    }
  });

  $('#cp').on('keyup', function() {
    var cp = $(this).val();

    if (cp.length == 5) {
      $('.error-input-cp').addClass('d-none');
      $('.btn-generales').removeClass('disabled');
    }else{
      $('.btn-generales').addClass('disabled');
      $('.error-input-cp').text('Código Postal No válido').removeClass('d-none');
    }
  });

  $('#lugar_cp').on('keyup', function() {
    var lugar_cp = $(this).val();

    if (lugar_cp.length == 5) {
      $('.error-input-lugar_cp').addClass('d-none');
      $('.btn-lugar').removeClass('disabled');
    }else{
      $('.btn-lugar').addClass('disabled');
      $('.error-input-lugar_cp').text('Código Postal No válido').removeClass('d-none');
    }
  });

  $('#telefono').on('keyup', function() {
    var telefono = $(this).val();

    if (telefono.length == 10) {
      $('.error-input-telefono').addClass('d-none');
      $('.btn-generales').removeClass('disabled');
    }else{
      $('.btn-generales').addClass('disabled');
      $('.error-input-telefono').text('Teléfono No válido').removeClass('d-none');
    }
  });

  $('#numero_telefonico').on('keyup', function() {
    var numero_telefonico = $(this).val();

    if (numero_telefonico.length == 10) {
      $('.error-input-numero_telefonico').addClass('d-none');
      $('.btn-celular').removeClass('disabled');
    }else{
      $('.btn-celular').addClass('disabled');
      $('.error-input-numero_telefonico').text('Teléfono No válido').removeClass('d-none');
    }
  });

  $('#numero_imei').on('keyup', function() {
    var numero_imei = $(this).val();

    if (numero_imei.length == 14 || numero_imei.length == 15) {
      $('.error-input-numero_imei').addClass('d-none');
      $('.btn-celular').removeClass('disabled');
    }else{
      $('.btn-celular').addClass('disabled');
      $('.error-input-numero_imei').text('El Número No válido').removeClass('d-none');
    }
  });

  $('#email').on('keyup', function() {
    var email = $(this).val();

    if (validateEmail(email)) {
      $('.error-input-email').addClass('d-none');
      $('.btn-generales').prop('disabled',false);
    }else{
      $('.btn-generales').prop('disabled',true);
      $('.error-input-email').text('Correo electrónico No válido').removeClass('d-none');
    }
  });

  $('#sm_emailmenor').on('keyup', function() {
    var email = $(this).val();

    if (validateEmail(email)) {
      $('.error-input-sm_emailmenor').addClass('d-none');
      $('.btn-sustraccion').prop('disabled',false);
    }else{
      $('.btn-sustraccion').prop('disabled',true);
      $('.error-input-sm_emailmenor').text('Correo electrónico No válido').removeClass('d-none');
    }
  });

  function initDate(){
    var fecha = $('#fecha_nacimiento').val();
    console.log('initDate fecha_nacimiento='+fecha);
    var edad = calcularEdad(fecha);
    $('#edad').val(edad);
  }

  $('#fecha_nacimiento').on('blur', function() {
    var fecha = $(this).val();
    console.log('blur fecha_nacimiento='+fecha);
    var edad = calcularEdad(fecha);
    $('#edad').val(edad);
  });

  $("#domicilio_extrangero").click( function(){
     if( $(this).is(':checked') ){
      $('.no-extranjero').addClass('d-none');
      $('.no-extranjero input').removeAttr( "required" );
      $('.no-extranjero select').removeAttr( "required" );

      $('.si-extranjero').removeClass('d-none');
      $('.si-extranjero textarea').prop('required',true);
     }else{
      $('.no-extranjero').removeClass('d-none');
      $('.no-extranjero input').prop('required',true);
      $('.no-extranjero select').prop('required',true);

      $('.si-extranjero').addClass('d-none');
      $('.si-extranjero textarea').removeAttr( "required" );
      $('#numero_interior').removeAttr( "required" );
     }
  });

  function horaValida(curp) {
    var re = '^([01]?[0-9]|2[0-3]):[0-5][0-9](:[0-5][0-9])?$',
        validado = curp.match(re);

    if (!validado)  //Coincide con el formato general?
      return false;

    return true; //Validado
  }
  function curpValida(curp) {
    var re = /^([A-Z][AEIOUX][A-Z]{2}\d{2}(?:0\d|1[0-2])(?:[0-2]\d|3[01])[HM](?:AS|B[CS]|C[CLMSH]|D[FG]|G[TR]|HG|JC|M[CNS]|N[ETL]|OC|PL|Q[TR]|S[PLR]|T[CSL]|VZ|YN|ZS)[B-DF-HJ-NP-TV-Z]{3}[A-Z\d])(\d)$/,
        validado = curp.match(re);

    if (!validado)  //Coincide con el formato general?
      return false;

    return true; //Validado
  }

  //Validar que coincida el dígito verificador
  function digitoVerificador(curp17) {
      //Fuente https://consultas.curp.gob.mx/CurpSP/
      var diccionario  = "0123456789ABCDEFGHIJKLMNÑOPQRSTUVWXYZ",
          lngSuma      = 0.0,
          lngDigito    = 0.0;
      for(var i=0; i<17; i++)
          lngSuma = lngSuma + diccionario.indexOf(curp17.charAt(i)) * (18 - i);
      lngDigito = 10 - lngSuma % 10;
      if (lngDigito == 10) return 0;
      return lngDigito;
  }

  function rfcValido(rfcStr) {
    var strCorrecta;
    strCorrecta = rfcStr;
    if (rfcStr.length == 12){
    var valid = '^(([A-Z]|[a-z]){3})([0-9]{6})((([A-Z]|[a-z]|[0-9]){3}))';
    }else{
    var valid = '^(([A-Z]|[a-z]|\s){1})(([A-Z]|[a-z]){3})([0-9]{6})((([A-Z]|[a-z]|[0-9]){3}))';
    }
    var validRfc=new RegExp(valid);
    var matchArray=strCorrecta.match(validRfc);
    if (matchArray==null) {
      return false;
    }else{
      return true;
    }
  }

  function calcularEdad(fecha) {
    var hoy = $('#fecha_nacimiento').attr('data-date');
    //hoy = new Date(hoy.substr(6, 4), hoy.substr(3, 2), hoy.substr(0, 2));
    hoy = new Date(hoy.substr(0, 4), hoy.substr(5, 2), hoy.substr(8, 2));

    //var cumpleanos = new Date(fecha.substr(6, 4), fecha.substr(3, 2), fecha.substr(0, 2));
    var cumpleanos = new Date(fecha.substr(0, 4), fecha.substr(5, 2), fecha.substr(8, 2));
    var edad = hoy.getFullYear() - cumpleanos.getFullYear();
    var m = hoy.getMonth() - cumpleanos.getMonth();

    if (m < 0 || (m === 0 && hoy.getDate() < cumpleanos.getDate())) {
       edad--;
    }

    return edad;
  }

  $( "#clase_vehiculo" ).change(function() {
    $( "#clase_vehiculo option:selected" ).each(function() {
      var clase = $( this ).attr('value');
      $('#tipo_vehiculo').empty();
      ajaxTipoVehiculos(clase);
    });
  });

  $( "#clase_vehiculo option:selected" ).each(function() {
    var clase = $( this ).attr('value');
    $('#tipo_vehiculo').empty();
    ajaxTipoVehiculos(clase);

  });

  function ajaxTipoVehiculos(clase){
    var url = $('#url').val();
    $.ajax({
      type: 'GET',
      url: url+"/tipos-vehiculos/"+clase,
      success: function(data) {
        $('#tipo_vehiculo').append(data.html);
        var tipo = $('#tipo_vehiculo-val').val();
        $('#tipo_vehiculo option[value="'+tipo+'"]').attr("selected", "selected");
      },
      error: function() {
          console.log('error');
      }
    });
  }

  $( "#marca_vehiculo, #rv_marca_vehiculo" ).change(function() {
    var marca = $(this).val();
    $('#submarca_vehiculo, #rv_submarca_vehiculo').empty();
    $('#submarca_vehiculo-val, #rv_submarca_vehiculo-val').val('');
    ajaxSubmarcaVehiculos(marca);
  });

  $( "#vf_agresorrelacion_ag" ).change(function() {
    if ($(this).val()==9) {  /* la cpcion es otros */
        $( "#div-vf_otra_relacion_ag" ).removeClass('d-none');
        $( "#vf_otra_relacion_ag" ).attr('required','required');
    } else {
        $( "#div-vf_otra_relacion_ag" ).addClass('d-none');
        $( "#vf_otra_relacion_ag" ).removeAttr('required');
    }
  });

  $( "#is_agresorrelacion_ag" ).change(function() {
    if ($(this).val()==9 || $(this).val()==12) {  /* la cpcion es otros */
        $( "#div-is_otra_relacion_ag" ).removeClass('d-none');
        $( "#is_otra_relacion_ag" ).attr('required','required');
	if ($(this).val()==9) { $("#l_is_otra_relacion_ag").text('Otra relación con el agresor'); }
	if ($(this).val()==12) { $("#l_is_otra_relacion_ag").text('Puesto del servidor público'); }
    } else {
        $( "#div-is_otra_relacion_ag" ).addClass('d-none');
        $( "#is_otra_relacion_ag" ).removeAttr('required');
    }
  });


  $( "#sm_relacionvictima" ).change(function() {
    if ($(this).val()==8) {  /* la cpcion es otros */
        $( "#sm_otrarela" ).prop('disabled',false);
        $( "#sm_otrarela" ).attr('required','required');
    } else {
        $( "#sm_otrarela" ).val('');
        $( "#sm_otrarela" ).prop('disabled',true);
        $( "#sm_otrarela" ).removeAttr('required');
    }
    $("#doctosrelacion input[type='file'] ").prop('disabled',true);
    rela=$("#sm_relacionvictima option:selected").text().split(" ");
    $("#doctosrelacion input[type='file'] ").prop('required',false);
    $("."+rela[0]).prop('disabled',false);
    if ($("."+rela[0]).length==1) {
        $("#doctosrelacion input[type='file'] ").prop('required',true);
    }
  });

  $( "#sm_relacionvictima" ).trigger('change');

  $( "#sm_relacionimputado" ).change(function() {
    if ($(this).val()==8) {  /* la cpcion es otros */
        $( "#sm_otrarelaimputado" ).prop('disabled',false);
        $( "#sm_otrarelaimputado" ).attr('required','required');
    } else {
        $( "#sm_otrarelaimputado" ).prop('disabled',true);
        $( "#sm_otrarelaimputado" ).val('');
        $( "#sm_otrarelaimputado" ).removeAttr('required');
    }
  });
  $( "#sm_relacionimputado" ).trigger('change');



  $( "#vf_agresorrelaciondocto_ag" ).change(function() {
    if ($(this).val()!=0) {  /* la cpcion es otros */
        $( "#div_vf_relaciondocto" ).removeClass('d-none');
        $( "#adjuntar").html('Adjunta tu '+$( "#vf_agresorrelaciondocto_ag  option:selected" ).text());
    } else {
        $( "#div_vf_relaciondocto" ).addClass('d-none');
        //$( "#vf_otra_relacion_ag" ).removeAttr('required');
    }
  });

  $( "#vf_nombre_co" ).change(function() {
    if ($(this).val()!='') {  /* la cpcion es otros */
        $( ".sicontacto").attr('required','required');
        $( ".sicontacto").removeAttr('disabled');
    } else {
        $( ".sicontacto").removeAttr('required');
        $( ".sicontacto[type!='radio']").val('');
        $( ".sicontacto").attr('disabled','disabled');
        $("#vf_segundo_apellido_co").val('');
    }
  });

  $( "#vf_nombre_co" ).trigger('change');


  $( "#marca_vehiculo option:selected, #rv_marca_vehiculo option:selected" ).each(function() {
    var clase = $( this ).attr('value');
    var marca = $(this).val();
    $('#submarca_vehiculo, #rv_submarca_vehiculo').empty();
    ajaxSubmarcaVehiculos(marca);
  });

  function validaDia(ano,mes,dia){
   errdia=true;
   if( mes==2 && ((ano/4)==parseInt(ano/4)) )
      { if(dia<=0 || dia>29) errdia=false; }
   if( mes==2 && ((ano/4)!=parseInt(ano/4)) )
      { if(dia<=0 || dia>28) errdia=false; }
   if( mes==4 || mes==6 || mes==9 || mes==11 )
      { if(dia<=0 || dia>30) errdia=false; }
   return errdia;
  }

  function ajaxSubmarcaVehiculos(marca){
    var url = $('#url').val();
    $.ajax({
      type: 'GET',
      url: url+"/submarca-vehiculos/"+marca,
      success: function(data) {
        $('#submarca_vehiculo, #rv_submarca_vehiculo').append(data.html);
        var tipo = $('#submarca_vehiculo-val, #rv_submarca_vehiculo-val').val();
        $('#submarca_vehiculo option[value="'+tipo+'"], #rv_submarca_vehiculo option[value="'+tipo+'"').attr("selected", "selected");
      },
      error: function() {
          console.log('error');
      }
    });
  }

  $('input[type=number][max]:not([max=""])').on('input', function(ev) { //* a los campos number que tiene max los limita a su longitud"
    var $this = $(this);
    var maxlength = $this.attr('max').length;
    var value = $this.val();
    if (value && value.length >= maxlength) {
      $this.val(value.substr(0, maxlength));
    }
  });

    $(".names").bind("keypress", function(event) {
        if (event.charCode != 0) {
            var regex = new RegExp("^[a-zA-ZñÑáéíóúÁÉÍÓÚ\s. ]+$");
            var key = String.fromCharCode(!event.charCode ? event.which : event.charCode);
            if (!regex.test(key)) {
                event.preventDefault();
                return false;
            }
        }
    });
    $(".letrasynumeros").bind("keypress", function(event) {
        if (event.charCode != 0) {
            var regex = new RegExp("^[a-zA-Z0-9ñÑ\s. ]+$");
            var key = String.fromCharCode(!event.charCode ? event.which : event.charCode);
            if (!regex.test(key)) {
                event.preventDefault();
                return false;
            }
        }
    });
    $(".serie").bind("keypress", function(event) {
        if (event.charCode != 0) {
            var regex = new RegExp("^[a-hj-np-pr-zA-HJ-NP-PR-Z0-9\s. ]+$");
            var key = String.fromCharCode(!event.charCode ? event.which : event.charCode);
            if (!regex.test(key)) {
                event.preventDefault();
                return false;
            }
        }
    });
    $(".placas").bind("keypress", function(event) {
        if (event.charCode != 0) {
            var regex = new RegExp("^[a-hj-np-pr-zA-HJ-NP-PR-Z0-9\s]+$");
            var key = String.fromCharCode(!event.charCode ? event.which : event.charCode);
            if (!regex.test(key)) {
                event.preventDefault();
                return false;
            }
        }
    });
    $.strPad = function(i,l,s) {
    	var o = i.toString();
    	if (!s) { s = '0'; }
    	while (o.length < l) {
    		o = s + o;
    	}
    	return o;
    };
    muestracheck();
});

document.addEventListener('DOMContentLoaded', function () {
	console.log('DOM cargado');
});
