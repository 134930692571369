import 'air-datepicker/dist/js/datepicker';
import 'air-datepicker/dist/js/i18n/datepicker.es';

$(function() {

    let target = $('.datepickerform');

    target.datepicker({
        language: 'es',
        dateFormat: 'dd/mm/yyyy',
        onSelect: function (date) {
            var edad = calcularEdadDate(date);
            $('#edad').val(edad);
        }
    });

    function calcularEdadDate(fecha) {
        var hoy = $('#fecha_nacimiento').attr('data-date');
        hoy = new Date(hoy.substr(6, 4), hoy.substr(3, 2), hoy.substr(0, 2));

        var cumpleanos = new Date(fecha.substr(6, 4), fecha.substr(3, 2), fecha.substr(0, 2));
        var edad = hoy.getFullYear() - cumpleanos.getFullYear();
        var m = hoy.getMonth() - cumpleanos.getMonth();

        if (m < 0 || (m === 0 && hoy.getDate() < cumpleanos.getDate())) {
            edad--;
        }

        return edad;
    }

    
});
