window.ReDrawCaptcha = function (x)
{
    nombre=x.id.replace(/_bot/,"_img");
    document.getElementById(nombre).value='';
    DrawCaptcha(x);
}
 window.DrawCaptcha = function(x)
{
    if (x.id.indexOf('_bot')==-1)
    { nombre=x.id+'_img'; }
    else { nombre=x.id.replace(/_bot/,"_img"); }
    if (document.getElementById(nombre).value!='') return;
    var a = Math.ceil(Math.random() * 10)+ '';
    var b = Math.ceil(Math.random() * 10)+ '';
    var c = Math.ceil(Math.random() * 10)+ '';
    var d = Math.ceil(Math.random() * 10)+ '';
    var e = Math.ceil(Math.random() * 10)+ '';
    var f = Math.ceil(Math.random() * 10)+ '';
    var g = Math.ceil(Math.random() * 10)+ '';
    var code = a + ' ' + b + ' ' + ' ' + c + ' ' + d + ' ' + e + ' '+ f + ' ' + g;
    document.getElementById(nombre).value = code;
}

// Validate the Entered input aganist the generated security code function
 window.ValidCaptcha = function(){
    var x = document.getElementById('wl_captcha');
    var str1 = removeSpaces(document.getElementById('wl_captcha_img').value.trim());
    var str2 = removeSpaces(x.value).trim();
    if ((str1 != str2) || str2=='')
    { x.value=''; x.focus(); return false ; }
    return true;
}
// Remove the spaces from the entered and generated code
 window.removeSpaces = function(string)
{
    return string.split(' ').join('');
}
