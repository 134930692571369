$(document).ready(function(){

  $('document').ready(function(){
	  if (document.getElementById('map-agencias') ){
	    initMap();
	  }
	});

	window.map;
	window.agencias;

	function initMap() {
	  var datos = agenciasmp;
	  console.log(datos);

	  var myStyles =[
	    {
	      "elementType": "geometry",
	      "stylers": [
	        {
	          "color": "#f5f5f5"
	        }
	      ]
	    },
	    {
	      "elementType": "labels.icon",
	      "stylers": [
	        {
	          "visibility": "off"
	        }
	      ]
	    },
	    {
	      "elementType": "labels.text.fill",
	      "stylers": [
	        {
	          "color": "#616161"
	        }
	      ]
	    },
	    {
	      "elementType": "labels.text.stroke",
	      "stylers": [
	        {
	          "color": "#f5f5f5"
	        }
	      ]
	    },
	    {
	      "featureType": "administrative.land_parcel",
	      "elementType": "labels.text.fill",
	      "stylers": [
	        {
	          "color": "#bdbdbd"
	        }
	      ]
	    },
	    {
	      "featureType": "poi",
	      "elementType": "geometry",
	      "stylers": [
	        {
	          "color": "#eeeeee"
	        }
	      ]
	    },
	    {
	      "featureType": "poi",
	      "elementType": "labels.text.fill",
	      "stylers": [
	        {
	          "color": "#757575"
	        }
	      ]
	    },
	    {
	      "featureType": "poi.park",
	      "elementType": "geometry",
	      "stylers": [
	        {
	          "color": "#e5e5e5"
	        }
	      ]
	    },
	    {
	      "featureType": "poi.park",
	      "elementType": "labels.text.fill",
	      "stylers": [
	        {
	          "color": "#9e9e9e"
	        }
	      ]
	    },
	    {
	      "featureType": "road",
	      "elementType": "geometry",
	      "stylers": [
	        {
	          "color": "#ffffff"
	        }
	      ]
	    },
	    {
	      "featureType": "road.arterial",
	      "elementType": "labels.text.fill",
	      "stylers": [
	        {
	          "color": "#757575"
	        }
	      ]
	    },
	    {
	      "featureType": "road.highway",
	      "elementType": "geometry",
	      "stylers": [
	        {
	          "color": "#dadada"
	        }
	      ]
	    },
	    {
	      "featureType": "road.highway",
	      "elementType": "labels.text.fill",
	      "stylers": [
	        {
	          "color": "#616161"
	        }
	      ]
	    },
	    {
	      "featureType": "road.local",
	      "elementType": "labels.text.fill",
	      "stylers": [
	        {
	          "color": "#9e9e9e"
	        }
	      ]
	    },
	    {
	      "featureType": "transit.line",
	      "elementType": "geometry",
	      "stylers": [
	        {
	          "color": "#e5e5e5"
	        }
	      ]
	    },
	    {
	      "featureType": "transit.station",
	      "elementType": "geometry",
	      "stylers": [
	        {
	          "color": "#eeeeee"
	        }
	      ]
	    },
	    {
	      "featureType": "water",
	      "elementType": "geometry",
	      "stylers": [
	        {
	          "color": "#c9c9c9"
	        }
	      ]
	    },
	    {
	      "featureType": "water",
	      "elementType": "labels.text.fill",
	      "stylers": [
	        {
	          "color": "#9e9e9e"
	        }
	      ]
	    }
	  ];

	  window.map = new google.maps.Map(document.getElementById('map-agencias'), {
	    zoom: 15,
	    center: new google.maps.LatLng(19.4329, -99.1332),
	    mapTypeId: google.maps.MapTypeId.ROADMAP,
	    styles: myStyles,
	    mapTypeControl: false,
	    streetViewControl: false,
	    fullscreenControl: false
	  });

	  window.agencias = setMarkers(datos);
	  
	}

	function setMarkers(oficinas) {
	  var markers= [];
	  for (var i = 0; i < oficinas.length; i++) {
	    var venueMarker = addMarkerWithTimeout(oficinas[i], i * 200);
	    markers.push(venueMarker);
	  }

	  return markers;
	}

	function addMarkerWithTimeout(position, timeout) {

	  var image = $('#market').val();
	  
	  var contentString = '<p class="text-tooltip">'+position.nombre+' <br> '+position.direccion+'</p>';

	  var locationInfowindow = new google.maps.InfoWindow({
	    content: contentString
	  });

	  var icon = {
	    url: $('#marker').val(), // url
	    size: new google.maps.Size(31, 38),
	    origin: new google.maps.Point(0, 0),
	    anchor: new google.maps.Point(17, 34),
	    scaledSize: new google.maps.Size(31, 38), // size
	  };
	  
	  var myLatlng = new google.maps.LatLng(parseFloat(position.lat),parseFloat(position.lng));

	  var beachMarker = new google.maps.Marker({
	    position: myLatlng,
	    map: window.map,
	    icon: icon,
	    infowindow: locationInfowindow
	  });

	  beachMarker.addListener('click', function() {
            console.log('hay botones cerrar='+$('button[title="Cerrar"]').length);
            $('button[title="Cerrar"]').trigger('click');
	    this.infowindow.open(map, this);
	  });

	  return beachMarker;
}
});
