$(document).ready(function(){
  fixedFooter();

  $( window ).scroll(function() {
    fixedFooter();
  });
  var searchParams = new URLSearchParams(window.location.search);
  if (searchParams.has('listllave')) {
	  $('#botonllave').attr('src','images/Botón_DenunciaDig.svg');
  }
  $(window).resize(function(){
    fixedFooter();
  });


  if ($('#home-denuncia').length>0) {
	 $.removeCookie('opcion_login', { path: '/' });
	  $.removeCookie('subopcion_login', { path: '/' });
	  $.removeCookie('login_user', { path: '/denuncia-digital/public' });
	  $.removeCookie('firma', { path: '/' });
  }
  /**
   * VALIDAR LOGIN O ABRIR MODAL PARA LOGIN
   */
  $('.btn-validate-login').on('click', function(event){
    var p_ask_data = $('#acepto-aviso').is(':checked');
    var user = $(this).attr('data-user');

    if ( ! p_ask_data ) {
            event.preventDefault();
	    var template_error = '<div class="tooltip " role="tooltip"><div class="arrow "></div><div class="tooltip-inner tooltip-inner-alert blink_me"></div></div>'
	    var msg="Te falta aceptar el aviso de privacidad";
	    var aviso=$('#acepto-aviso');
	                      aviso.attr('data-toggle','tooltip');
                              aviso.attr('title',msg);
                              aviso.attr('data-trigger','manual');
                              aviso.attr('data-template',template_error);
                              aviso.tooltip('show')
                              setTimeout(function(){ aviso.tooltip('hide'); }, 5000 );
	                      aviso.focus();
	    return false;
    }

    var opcion = $(this).attr('data-opcion');
    Cookies.set('opcion_login', opcion, { expires: 1 });
    Cookies.set('subopcion_login', this.name, { expires: 1 });

    if ( Cookies.get('login_user') === undefined || Cookies.get('firma') == 1 || user == 'no') {
      event.preventDefault();
      redirecturl='&redirect_url='+miurl+opcion;
      $('#urllogin').attr('href',$('#urllogin').attr('href')+redirecturl)
      $('#modal-login').removeClass('d-none');
      $('body').css({'overflow': 'hidden'});
    }
  });

  $('.send-fiel').on('click', function(event){
    console.log('asd');
    Cookies.set('login_user', 1, { expires: 1 });
    Cookies.set('firma', 1, { expires: 1 });

    if(Cookies.get('opcion_login') == '/login-llave'){
	 if (Cookies.get('subopcion_login') == '/delitoelectoral') {
		 Cookies.set('subopcion_login', 'Pasodelitoelectoral', { expires: 1 });
		 Cookies.set('delito', '333', { expires: 1 });
		 $.redirect(miurl+"/delitos", { '_token': $('meta[name="csrf-token"]').attr('content') },'POST');
	 } else {
            window.location.href = miurl+'/victima-delito';
         }
    }else{
      window.location.href = miurl+'/perdiste-algo';
    }
  });

  /**
   * ABRIR MODAL CON ORMULARIO DE LOGIN
   */
  $('.open-modal-form').on('click', function(){
      $('#modal-login').addClass('d-none');
      $('#modal-form').removeClass('d-none');
      $('body').css({'overflow': 'hidden'});
  });


  $('.login-adu').on('click', function(){
    var url = $('#url').val();

    $.ajax({
      type: 'GET',
      url: url+"/login-adu/",
      success: function(data) {
        $('#municipio').append(data.html);
        var municipio = $('#municipio-val').val();
        if(municipio > 0){
          $('#municipio option[value="'+municipio+'"]').attr("selected", "selected");
          ajaxColonias(municipio, estado, url);
        }
      },
      error: function() {
          console.log('error');
      }
    });
  });

  /**
   * CERRAR MODALS DEL HOME
   */
  $('.close-modal-home').on('click', function(event){
    event.preventDefault();

    $('.modal-login').addClass('d-none');
    $('body').css({'overflow': 'initial'});
  });

});

window.fixedFooter=function(){
  var width = jQuery(window).width();
  $('.questions').css('height', '');
  var height = jQuery(window).height()-jQuery('#footerIframe').height();
  //var heightBody = jQuery(document).height() ;
  var heightBody = jQuery('header').outerHeight(true)+jQuery('.questions').outerHeight(true)+jQuery('.pleca').outerHeight(true) ;

  if(height >= heightBody){
    //console.log('fijo el footer  height='+height+' heightBody='+heightBody+' questions='+jQuery('.questions').outerHeight(true));
    qh=$('.questions').height();
    di= height-heightBody;
    $('.questions').height(qh+di);
    $('#footerIframe').addClass('fixed');
  }else{
    $('#footerIframe').removeClass('fixed');
  }
}
